<script setup>
import { computed, defineProps, defineEmits, onBeforeMount, ref } from 'vue';
import StatusGrid from '../StatusGrid';
import { getClientSesmt } from '../../../../services/http-sesmt';
import StatusGroDadosConclusivosEnum from '../../../../enums/StatusGroDadosConclusivos';
import { useAlert } from '../../../../composables/useAlert';

const emit = defineEmits(['update:status']);
const { alertSucess, alertErrorData } = useAlert();

const props = defineProps({
    elaboracaoId: {
        type: Number
    },
    dataVisita: {
        type: String
    },
    observacao: {
        type: String
    },
    avaliador: {
        type: String
    },
    acompanhante: {
        type: String
    },
    funcaoAcompanhante: {
        type: String
    },
    status: {
        type: String
    },
    inicio: {
        type: String
    },
    conclusao: {
        type: String
    },
    duracao: {
        type: String
    }
});

const statusDadosConclusivosOpcoes = ref([]);
const styleMapStatusDadosConclusivos = {
    [StatusGroDadosConclusivosEnum.SEM_DADOS]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroDadosConclusivosEnum.PENDENTE]: {
        backgroundColor: '#F6DFC3',
        color: '#653E11'
    },
    [StatusGroDadosConclusivosEnum.VALIDADO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroDadosConclusivosEnum.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};

const statusModel = computed({
    get: () => props.status,
    set: (value) => {
        emit('update:status', value);
    }
});
const statusComputed = computed(() => {
    return getStatusDadosConclusivosComTodasAsConfigs(statusModel.value);
});

const isValidado = computed(() => statusModel.value === StatusGroDadosConclusivosEnum.VALIDADO);

const getStatusDadosConclusivosComTodasAsConfigs = (status) => statusDadosConclusivosOpcoes.value.find((p) => p.value === status);

const loadingValidar = ref(false);
async function validar() {
    try {
        loadingValidar.value = true;
        const response = await getClientSesmt().patch(`aplicacao-pgr-elaboracao/validar-dados-conclusivos/${props.elaboracaoId}`);
        statusModel.value = response.data?.statusDadosConclusivos;
        alertSucess('Dados conclusivos validados com sucesso.');
    } catch (error) {
        alertErrorData(error, 'Não foi possível validar os dados conclusivos. ');
    } finally {
        loadingValidar.value = false;
    }
}

const getStatusDadosConclusivosOpcoes = () => {
    statusDadosConclusivosOpcoes.value = Object.keys(StatusGroDadosConclusivosEnum).map(function (type) {
        return {
            label: `${StatusGroDadosConclusivosEnum[type]}`,
            value: `${type}`,
            style: styleMapStatusDadosConclusivos[StatusGroDadosConclusivosEnum[type]]
        };
    });
};

onBeforeMount(() => {
    getStatusDadosConclusivosOpcoes();
});
</script>
<template>
    <div class="block-card-visita flex flex-column mb-4 p-4 border-round-md border-solid border-200">
        <div class="flex align-items-center justify-content-between">
            <div class="conteudo-visita">
                <strong class="titulo">Dados da visita técnica</strong>
                <div class="flex gap-8">
                    <div class="flex flex-column">
                        <div class="dados mt-3">
                            <span>Visita técnica de: </span> <strong> {{ props.dataVisita }}</strong>
                        </div>
                        <div class="dados">
                            <span>Avaliador: </span> <strong> {{ props.avaliador }}</strong>
                        </div>
                        <div class="dados">
                            <span>Inicio: </span> <strong> {{ inicio }}</strong>
                        </div>
                        <div class="dados">
                            <span>Duração: </span> <strong>{{ duracao }}</strong>
                        </div>
                    </div>

                    <div>
                        <div class="dados">
                            <span>Acompanhante: </span> <strong>{{ props.acompanhante }}</strong>
                        </div>
                        <div class="dados">
                            <span>Função do acompanhante: </span> <strong>{{ props.funcaoAcompanhante }}</strong>
                        </div>
                        <div class="dados">
                            <span>Conclusão: </span> <strong>{{ conclusao }}</strong>
                        </div>
                    </div>
                </div>

                <div class="dados mt-4">
                    <span>Observação: </span> <strong>{{ observacao }}</strong>
                </div>
            </div>
            <div class="flex flex-column align-items-end">
                <div class="mb-3">
                    <StatusGrid v-if="statusComputed" :status="statusComputed" />
                </div>
                <div class="mb-3">
                    <slot name="avaliar-trabalho-campo" />
                </div>
            </div>
        </div>
        <div class="mt-4">
            <slot name="registrar-pendencia" />
        </div>
        <div class="mt-4 flex flex-column align-items-end">
            <Button
                :loading="loadingValidar"
                :disabled="isValidado"
                label="Validar"
                icon="pi pi-check-circle"
                class="p-button-outlined"
                @click="validar"
            />
        </div>
    </div>
</template>
