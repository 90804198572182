<script setup>
import { ref, defineExpose, defineEmits, watch } from 'vue';
import PerguntaMultiplaEscolha from './PerguntaMultiplaEscolha.vue';
import PerguntaCombo from './PerguntaCombo.vue';
import PerguntaDissertativa from './PerguntaDissertativa.vue';
import PerguntaNumeroInteiro from './PerguntaNumeroInteiro.vue';
import FormRegistrarPendencia from '../FormRegistrarPendencia.vue';
import AnexarArquivos from '../AnexarArquivos.vue';
import { useAlert } from '../../../../composables/useAlert.js';
import { getClientSesmt } from '../../../../services/http-sesmt';
import SesmtService from '../../../../services/SesmtService';
import { useRouter } from 'vue-router';
import TipoAnexoEnum from '../../../../enums/TipoAnexoEnum';
import OrigemAnexoEnum from '../../../../enums/OrigemAnexoEnum';
import { helpers, requiredIf } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useFocusElement } from '../../../../utils/useFocusElement.js';

const { currentRoute } = useRouter();
const emit = defineEmits(['update:visible']);

const service = new SesmtService(
    `aplicacao-pgr-elaboracao/aplicacao-questionario/elaboracao/${currentRoute.value.params.idElaboracao}/update-by-origem`
);
const { alertError, alertSucess, alertErrorData } = useAlert();
const isOpen = ref(false);

const origemId = ref();
const form = ref([]);
const pendencias = ref();
const pendencia = ref();
const temPendencia = ref();

watch(
    () => pendencia.value?.pendencias,
    () => {
        temPendencia.value = pendencia.value?.pendencias.length;
    }
);

const componentsMap = {
    CB: PerguntaCombo,
    ME: PerguntaMultiplaEscolha,
    DI: PerguntaDissertativa,
    IN: PerguntaNumeroInteiro
};

const loading = ref(false);
const loadingSalvar = ref(false);

const load = async (id) => {
    try {
        loading.value = false;
        const { data: questionario } = await getClientSesmt().get(
            `aplicacao-pgr-elaboracao/aplicacao-questionario/find-by-inventario-risco-visita-tecnica/${id}/origem/INICIAL`
        );

        const { data: respostas } = await getClientSesmt().get(
            `aplicacao-pgr-elaboracao/aplicacao-respostas/origem-id/${questionario.id}/origem/INICIAL/`
        );

        form.value = respostas?.aplicacaoResposta;

        origemId.value = questionario.id;
        pendencias.value = respostas?.aplicacaoPgrElaboracaoPendencia;
    } catch (error) {
        const { message } = error?.response?.data || { message };
        alertError(`Erro ao listar! ${message}`);
    } finally {
        loading.value = false;
    }
};

const openDialog = async (id) => {
    await load(id);
    isOpen.value = true;
};

const closeDialog = () => {
    emit('update:visible');
    v$.value.$reset();
    isOpen.value = false;
};

const fieldsRef = ref([]);
const rules = {
    form: {
        $each: helpers.forEach({
            resposta: {
                required: helpers.withMessage(
                    'Resposta obrigatória',
                    requiredIf((_, itemForm) => itemForm.perguntaObrigatoria)
                )
            }
        })
    }
};

const v$ = useVuelidate(rules, { form });

const salvar = async () => {
    const isValid = await v$.value.form.$validate();

    if (!isValid) {
        focarCampoObrigatorio();
        return;
    }

    try {
        loadingSalvar.value = true;
        await service.save({
            id: form.value[0].origemId,
            origemId: form.value[0].origemId,
            origem: form.value[0].origem,
            inventarioRiscoId: form.value[0].inventarioRiscoId,
            perguntasRespostas: form.value.map((item) => ({
                id: item.id,
                resposta: item.resposta,
                descricao: item.descricao ?? item.perguntaSesmt.descricao,
                mensagemAjuda: item.mensagemAjuda ?? item.perguntaSesmt.mensagemAjuda,
                tipo: item.tipo ?? item.perguntaSesmt.tipo,
                opcoes: item.opcoes ?? item.perguntaSesmt.opcoes
            })),
            questionarioSesmtId: form.value[0].questionarioSesmtId,
            etapa: form.value[0].etapa,
            inserirAnexo: form.value[0].questionarioSesmt.inserirAnexo,
            anexoObrigatorio: form.value[0].questionarioSesmt.inserirAnexo,
            respostasConcatenadas: form.value.map((item) => `${item.descricao ?? item.perguntaSesmt.descricao}: ${item.resposta}`).join(' - ')
        });
        closeDialog();
        alertSucess('Questionário salvo com sucesso.');
    } catch (error) {
        if (error?.response?.data?.details?.response?.retornoErrosCadastro?.contemErrosCadastro) {
            closeDialog();
            return;
        }

        alertErrorData(error, 'Não foi possível salvar o questionário, por favor tente novamente.');
    } finally {
        loadingSalvar.value = false;
    }
};

const encontrarPrimeiroCampoComErro = () => {
    return v$.value.form.$model.findIndex((_, index) => v$.value.form.$each.$response.$errors[index].resposta?.length);
};

const focusElement = useFocusElement();
const focarCampoObrigatorio = () => {
    const firstErrorFieldIndex = encontrarPrimeiroCampoComErro();

    if (firstErrorFieldIndex !== -1 && fieldsRef.value[firstErrorFieldIndex]) {
        const identificacao = `campo-${firstErrorFieldIndex}`;
        const element = document.getElementById(identificacao);

        if (element) {
            focusElement.byId(identificacao);

            return;
        }

        focusElement.byClassName(identificacao);
    }
};

defineExpose({
    closeDialog,
    openDialog
});
</script>
<template>
    <Dialog v-model:visible="isOpen" :style="{ width: '688px', height: 'auto' }" :draggable="false" :modal="true">
        <template #header>
            <h3 style="font-weight: 600">Administrativo</h3>
        </template>

        <div class="grid flex flex-column align-items-start">
            <div v-for="(item, index) in form" :key="item.id" class="col-6 mb-3 w-full">
                <component
                    :is="componentsMap[item.tipo ?? item.perguntaSesmt.tipo]"
                    :tipo="item.tipo ?? item.perguntaSesmt.tipo"
                    :descricao="item.descricao ?? item.perguntaSesmt.descricao"
                    :opcoes="item.opcoes ?? item.perguntaSesmt.opcoes"
                    :ordem="item.ordem"
                    :obrigatoria="item.perguntaObrigatoria"
                    v-model="v$.form.$model[index].resposta"
                    :isInvalid="Boolean(v$?.form?.$each?.$response?.$errors?.[index].resposta?.length && v$.form.$dirty)"
                    :elementId="`campo-${index}`"
                    :ref="(el) => (fieldsRef[index] = el)"
                    :outros="item?.outros || item?.perguntaSesmt?.outros"
                />

                <small v-if="v$?.form?.$each?.$response?.$errors?.[index].resposta?.length && v$.form.$dirty" class="p-error">
                    {{ v$?.form?.$each?.$response?.$errors?.[index].resposta?.[0].$message }}
                </small>
            </div>
        </div>

        <div class="w-full mt-6 mb-6">
            <AnexarArquivos :origemId="origemId" :origem="OrigemAnexoEnum.CHECKLIST_INICIAL" :tipo="TipoAnexoEnum.CHECKLIST_INICIAL" />
        </div>
        <Message v-if="temPendencia" class="mb-5" severity="info">Só é possível validar se todas as pendências estiverem concluídas.</Message>
        <FormRegistrarPendencia ref="pendencia" :origemId="origemId" origem="INICIAL" />
        <template #footer>
            <Button label="Cancelar" class="p-button-text justify-content-end" @click="closeDialog()" />

            <Button
                :label="temPendencia ? 'Salvar' : 'Salvar e validar'"
                class="p-button-primary justify-content-end"
                @click="salvar"
                :loading="loadingSalvar"
            />
        </template>
    </Dialog>
</template>

<style>
.p-fileupload .p-button .p-button-icon {
    display: none !important;
}

.botao-com-altura-fixa {
    height: 40px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
