<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { getClientSesmt } from '../../../../services/http-sesmt';
import { useAlert } from '../../../../composables/useAlert.js';

const { alertSucess, alertErrorData } = useAlert();
const { currentRoute } = useRouter();

const isOpen = ref(false);
const isLoading = ref(false);
const form = ref({});
const loadingAvaliar = ref(false);

const labelTooltip = {
    1: 'Péssimo',
    2: 'Ruim',
    3: 'Regular',
    4: 'Bom',
    5: 'Excelente'
};

const openDialog = async () => (isOpen.value = true);

const closeDialog = () => {
    form.value = {};
    isOpen.value = false;
};

const avaliar = async () => {
    try {
        loadingAvaliar.value = true;

        form.value.pgrElaboracaoId = currentRoute.value.params.idElaboracao;

        await getClientSesmt().post(`aplicacao-pgr-elaboracao/elaborar/avaliacao`, form.value);

        closeDialog();
        alertSucess('Descrição salva com sucesso.');
    } catch (error) {
        if (error?.response?.data?.details?.response?.retornoErrosCadastro?.contemErrosCadastro) {
            closeDialog();
            return;
        }

        alertErrorData(error, 'Não foi possível salvar avaliação, por favor tente novamente.');
    } finally {
        loadingAvaliar.value = false;
    }
};
</script>
<template>
    <Button label="Avaliar trabalho de campo" icon="pi pi-file-edit" class="p-button-outlined" @click="openDialog" />
    <Dialog
        v-model:visible="isOpen"
        :style="{ width: '500px' }"
        header="Descrição detalhada"
        @hide="closeDialog"
        :draggable="false"
        modal
        :closable="!isLoading"
    >
        <div v-if="!isLoading" class="flex flex-column gap-2 w-full line-height-3">
            <div class="flex flex-column">
                <label for="avaliacao" class="mb-3">Dê sua nova avaliação do trabalho de campo</label>
                <Rating v-tooltip.left="labelTooltip[form.avaliacao]" :stars="5" v-model="form.avaliacao" :cancel="false" />
            </div>
            <div class="flex flex-column gap-2 mt-4">
                <label for="cometarioAvaliacao">Comentário sobre a avaliação</label>
                <InputText id="username" v-model="form.comentario" aria-describedby="username-help" />
            </div>
        </div>
        <template #footer>
            <div class="flex aling-itens-end pt-4 justify-content-end">
                <Button class="button-link" @click="closeDialog" text label="Cancelar" />
                <Button :disabled="!form.avaliacao" class="p-button" :loading="loadingAvaliar" type="button" label="Avaliar" @click="avaliar" />
            </div>
        </template>
    </Dialog>
</template>
